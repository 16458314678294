<template>
  <div id="app" class="md-layout">
    <SiderBar />
    <router-view />
    <Vuedal />
  </div>
</template>
<script>
import { Bus as VuedalsBus } from 'vuedals';
import UserService from './services/user';
import router from './router';

export default {
  name: 'App',
  props: {},
  data: () => ({ userService: new UserService() }),

  beforeMount() {
    router.beforeEach((_to, _from, next) => {
      VuedalsBus.$emit('close', []);
      next();
    });
  },
  methods: {},
};
</script>
<style lang="scss">
:root {
  --md-theme-default-fieldvariant: #fe1c1d;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  flex-wrap: nowrap !important;
  background: #f2f2f2;
}
html,
body,
#fullheight {
  min-height: 100% !important;
  height: 100%;
}
#nav {
  padding: 30px;
}
.vuedals {
  display: flex;
  align-items: center;
}
.vuedal {
  .withoutCross + header {
    display: none;
  }
}
input[readonly],
input[readonly]:focus {
  background-color: #dee2e6 !important;
}
form {
  label {
    margin-bottom: 0 !important;
  }
}
.vuedal {
  width: unset !important;
  padding: unset !important;
  margin: 0 !important;
  .close {
    position: absolute;
    right: 23px;
    top: 20px;
    transform: scale(1.2);
  }
  header {
    border: unset !important;
    min-height: 0 !important;
    margin: 0 !important;
  }
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.md-error {
  text-align: left;
  color: #fe1c1d;
}
span,
label,
p,
a:link,
a:visited {
  text-decoration: none !important;
}
button {
  font-family: Roboto;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
select {
  background: #ffffff;
  border: 1px solid #afafaf;
  box-sizing: border-box;
}
textarea,
input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #afafaf;
}
input:focus,
textarea:focus,
select:focus {
  box-shadow: none;
  outline: 0;
  outline-offset: 0;
  border: 1px solid #737373 !important;
}

#overlay-spinner {
  position: absolute !important;
}
</style>
