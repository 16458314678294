import ApiService from './api';

class UserService {
  constructor() {
    this.apiService = new ApiService();
  }

  loginUser(user) {
    const body = { username: user.userName, password: user.password };
    return this.apiService.post('/api/v1/auth/login', body);
  }

  getCurrentUser(userId) {
    return this.apiService.get(`/api/v1/users/${userId}`);
  }

  changePassword(currentPassword, newPassword, userId) {
    return this.apiService.put(`/api/v1/users/${userId}/changePassword`, {
      currentPassword,
      newPassword,
    });
  }
}

export default UserService;
