<template>
  <div style="flex: 1; position: relative">
    <img src="@/assets/search_icon.svg" />
    <input
      v-model="search"
      class="md-layout-item md-size-50"
      type="text"
      :style="{ background: backgroundColor }"
      :placeholder="placeholder"
      @keyup="onSearch()"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchComponent',
  props: {
    options: null,
    placeholder: null,
    backgroundColor: {
      type: String,
      default: 'white',
    },
  },
  data: () => ({
    search: '',
    timeout: null,
  }),
  methods: {
    onSearch() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.updateOptions({
          ...this.options,
          offset: 0,
          search: this.search?.trim(),
        });
      }, 170);
    },
    updateOptions(options) {
      this.$emit('optionsChange', { ...options });
    },
  },
};
</script>
<style scoped lang="scss">
img {
  position: absolute;
  top: 10px;
  left: 10px;
}
input {
  padding-left: 44px;
  background: #ffffff;
  box-shadow: 0px 0px 0px #e4e7eb;
  border-radius: 4px;
  border: 0;
  height: 36px;
  max-width: 620px;
  width: 620px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
