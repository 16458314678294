import axios from 'axios';
import { Observable } from 'rxjs';
import { store } from '../main';
import router from '../router';
import UserService from './user';

class ApiService {
  constructor() {
    this.options = {};
    (this.updateHeaders = () => {
      this.options.headers = {
        Authorization: `Bearer ${store.state.token}`,
      };
    })(
      (this.updateToken = () =>
        new Promise((resolve) => {
          this.token = store.state.token;

          if (this.token) {
            this.updateHeaders();
            const jwt = require('jsonwebtoken');
            const parsedToken = jwt.decode(this.token);
            const userId = parsedToken.id;
            new UserService().getCurrentUser(userId).subscribe((resp) => {
              store.commit('saveUser', resp.data.message);
              resolve(this.token);
            });
          } else {
            this.refreshToken().then((res) => {
              store.commit('saveToken', res.data.message.accessToken);
              this.updateToken();
            });
          }
        }))
    );
  }

  post(url, body, headers = {}) {
    return new Observable((observer) => {
      const post = () =>
        axios.post(url, body, {
          ...this.options,
          ...headers,
        });

      const doPost = () =>
        post()
          .then((resp) => {
            observer.next(resp);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.refreshToken().then(
                (res) => {
                  store.commit('saveToken', res.data.message.accessToken);
                  this.updateToken();
                  doPost();
                },
                () => {
                  this.logOut();
                  observer.error();
                }
              );
            } else {
              observer.error(error.response);
            }
          });
      doPost();
    });
  }

  get(url, headers = {}) {
    return new Observable((observer) => {
      this.updateHeaders();
      const get = () =>
        axios.get(url, {
          ...this.options,
          ...headers,
        });

      const doGet = () =>
        get()
          .then((resp) => {
            observer.next(resp);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.refreshToken().then(
                (res) => {
                  store.commit('saveToken', res.data.message.accessToken);
                  this.updateToken().then(() => {
                    doGet();
                  });
                },
                () => {
                  this.logOut();
                  observer.error();
                }
              );
            } else {
              observer.error(error.response);
            }
          });
      doGet();
    });
  }

  put(url, body, headers = {}) {
    return new Observable((observer) => {
      const put = () =>
        axios.put(url, body, {
          ...this.options,
          ...headers,
        });

      const doPut = () =>
        put()
          .then((resp) => {
            observer.next(resp);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.refreshToken().then(
                (res) => {
                  store.commit('saveToken', res.data.message.accessToken);
                  this.updateToken();
                  doPut();
                },
                () => {
                  this.logOut();
                  observer.error();
                }
              );
            } else {
              observer.error(error.response);
            }
          });
      doPut();
    });
  }

  delete(url) {
    return new Observable((observer) => {
      const Delete = () =>
        axios.delete(url, {
          ...this.options,
        });

      const doDelete = () =>
        Delete()
          .then((resp) => {
            observer.next(resp);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.refreshToken().then(
                (res) => {
                  store.commit('saveToken', res.data.message.accessToken);
                  this.updateToken();
                  doDelete();
                },
                () => {
                  this.logOut();
                  observer.error();
                }
              );
            } else {
              observer.error(error.response);
            }
          });
      doDelete();
    });
  }

  refreshToken() {
    return axios.get('/api/v1/auth/refreshTokens', {
      ...this.options,
    });
  }

  logOut() {
    return axios
      .get('/api/v1/auth/logout', {
        ...this.options,
      })
      .finally(() => {
        router.push('/');
      });
  }
}
export default ApiService;
