import Vue from 'vue';
import Vuex from 'vuex';
import VueMaterial from 'vue-material';
import { Datetime } from 'vue-datetime'
import { default as Vuedals, Component as Vuedal } from 'vuedals';
import loader from 'vue-ui-preloader';
import App from './App.vue';
import router from './router';
import SiderBar from '@/components/SiderBar';
import pageNavigator from '@/components/pageNavigation';
import searchComponent from '@/components/searchComponent';

import 'vue-datetime/dist/vue-datetime.css'
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import customerViewModal from './views/customersViewCustomerModal';
import deleteCustomer from './views/customersDeleteModal';
import deleteMonitor from './views/monitorsDeleteModal';
import monitorViewModal from './views/monitorsViewModal';
import perTimeGraph from './views/assetItemPerTimeGraphSection';

Vue.use(loader);

Vue.use(Vuedals);
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    token: null,
    user: null,
    routesParams: {
      customers: { isShowSideBar: true, isForAdmin: true },
      login: { isShowSideBar: false, isForAdmin: true },
      monitors: { isShowSideBar: true, isForAdmin: true },
      settings: { isShowSideBar: true, isForAdmin: true },
      assetList: { isShowSideBar: false, isForAdmin: false },
      assetItem: { isShowSideBar: false, isForAdmin: false },
    },
  },
  mutations: {
    saveToken(state, token) {
      state.token = token;
    },
    saveUser(state, user) {
      state.user = user;
    },
  },
});

Vue.use(VueMaterial);
Vue.component('datetime', Datetime);
Vue.component('SiderBar', SiderBar);
Vue.component('PageNavigator', pageNavigator);
Vue.component('SearchComponent', searchComponent);
Vue.component('Vuedal', Vuedal);
Vue.component('DeleteCustomer', deleteCustomer);
Vue.component('PerTimeGraph', perTimeGraph);
Vue.component('CustomerViewModal', customerViewModal);
Vue.component('MonitorViewModal', monitorViewModal);
Vue.component('DeleteMonitor', deleteMonitor);
export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
