import ApiService from './api';

class MonitorsService {
  constructor() {
    this.apiService = new ApiService();
  }

  getMonitors(options) {
    let url = 'api/v1/devices/';
    if (options) {
      const optionsParams = new URLSearchParams();
      if (options.limit){
        optionsParams.set('limit', +options.limit);
      }
      if (options.offset){
        optionsParams.set('offset', +options.offset);
      }
      if (options.search) {
        optionsParams.set('search', options.search);
      }
      url += `?${optionsParams.toString()}`;
    }
    return this.apiService.get(url);
  }

  getMonitor(id, options) {
    let url = `api/v1/devices/${id}`;
    if (options) {
      const optionsParams = new URLSearchParams();
      if (options.includeConfig) {
        optionsParams.set('includeConfig', options.includeConfig);
      }
      url += `?${optionsParams.toString()}`;
    }
    return this.apiService.get(url);
  }

  getMonitorImage(deviceId) {
    return this.apiService.get(`/api/v1/devices/${deviceId}/image`, {
      responseType: 'arraybuffer',
    });
  }

  getPacketData(options) {
    let url = '/api/v1/packet-data/graphic';
    if (options) {
      const optionsParams = new URLSearchParams();
      if (options.deviceId) {
        optionsParams.set('deviceId', options.deviceId);
      }
      if (Number.isInteger(options.startDate)) {
        optionsParams.set('startDate', options.startDate);
      }
      if (Number.isInteger(options.endDate)) {
        optionsParams.set('endDate', options.endDate);
      }
      if (options.graphicType) {
        optionsParams.set('graphicType', options.graphicType);
      }
      if (options.detalization) {
        optionsParams.set('detalization', options.detalization);
      }
      url += `?${optionsParams.toString()}`;
    }
    return this.apiService.get(url);
  }

  getHealthStatus(id) {
    return this.apiService.get(`api/v1/devices/${id}/status`);
  }
}

export default MonitorsService;
