<template>
  <form autocomplete="off" @submit.prevent="submit()">
    <div>
      <div class="form_header">
        <h1 v-if="mode === 'create'">Add New Customer</h1>
        <h1 v-if="mode === 'edit'">Edit Customer</h1>
      </div>
      <div class="customer_view">
        <div>
          <label for="customer_name">Customer name</label>
          <input
            id="customer_name"
            v-model="form.customerName"
            :class="getValidationClass('customerName')"
            type="text"
          />
          <span
            v-if="!$v.form.customerName.required && $v.form.customerName.$dirty"
            class="md-error"
            >Required</span
          >
          <span
            v-if="!$v.form.customerName.maxLength && $v.form.customerName.$dirty"
            class="md-error"
            >Should have no more than 64 characters</span
          >
        </div>
        <div v-if="customer">
          <label for="customer_username">Username</label>
          <input id="customer_username" :value="customer.username" readonly type="text" />
        </div>

        <div v-if="mode === 'create'">
          <label for="password">Password</label>
          <input
            id="password"
            v-model="form.password"
            :class="getValidationClass('password')"
            :disabled="mode === 'edit'"
            type="password"
          />
          <span
            v-if="!$v.form.password.required && $v.form.password.$dirty && mode === 'create'"
            class="md-error"
            >Required</span
          >
          <span
            v-if="$v.form.password.$invalid && $v.form.password.$dirty && $v.form.password.required"
            class="md-error"
            >Should have 8-16 characters: alphabet and at least one numeric and !#$%&amp;*+-.?@_
            symbols</span
          >
        </div>
        <div v-if="mode === 'edit'">
          <label for="newPassword">New Password</label>
          <input
            id="password"
            v-model="form.password"
            :class="getValidationClass('password')"
            type="password"
          />
          <span
            v-if="!$v.form.password.required && $v.form.password.$dirty && mode === 'create'"
            class="md-error"
            >Required</span
          >
          <span v-if="$v.form.password.$invalid && $v.form.password.$dirty" class="md-error"
            >Should have 8-16 characters, alphabet, numeric and !#$%<span>{{ '&' }}</span
            >*+-.?@_ symbols</span
          >
        </div>
        <div v-if="mode === 'edit'">
          <label for="repeatPassword">Confirm Password</label>
          <input
            id="repeatPassword"
            v-model="form.repeatPassword"
            :class="getValidationClass('repeatPassword')"
            type="password"
          />
          <span
            v-if="
              !$v.form.repeatPassword.required && $v.form.repeatPassword.$dirty && mode === 'create'
            "
            class="md-error"
            >Required</span
          >

          <span
            v-if="form.password !== form.repeatPassword && $v.form.repeatPassword.$dirty"
            class="md-error"
            >The confirm password is incorrect</span
          >
          <span
            v-if="$v.form.repeatPassword.$invalid && $v.form.repeatPassword.$dirty"
            class="md-error"
            >Should have 8-16 characters, alphabet, numeric and !#$%<span>{{ '&' }}</span
            >*+-.?@_ symbols</span
          >
        </div>
        <div class="additional_info">
          <label for="additional_info">Additional info</label>
          <textarea
            id="additional_info"
            v-model="form.additional_info"
            :class="getValidationClass('additional_info')"
            type="text"
          />
          <span
            v-if="!$v.form.additional_info.maxLength && $v.form.additional_info.$dirty"
            class="md-error"
            >Your input should be less than 512 symbols</span
          >
        </div>
        <div class="button">
          <md-button v-if="mode === 'create'" type="submit" class="md-raised md-primary">
            Create
          </md-button>
          <md-button v-if="mode === 'edit'" type="submit" class="md-raised md-primary">
            Apply
          </md-button>
        </div>
      </div>
    </div>
  </form>
</template>
<style scoped lang="scss">
.md-invalid {
  border: 1px solid #fe1c1d !important;
}
.settings_header {
  text-align: left;
}
.md-error {
  text-align: left;
  color: #fe1c1d;
}
form {
  padding: 50px;
  max-height: 58vh;
  width: 50vw;
  overflow: auto;
  .form_header {
    text-align: left;
    h1 {
      margin-bottom: 15px;
      margin-top: 0;
    }
  }
}
.customer_view {
  display: grid;
  grid-gap: 30px 45px;

  grid-template-columns: 1fr 1fr;
  div {
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 9px !important;

    /* identical to box height, or 150% */
    letter-spacing: 0.15px;

    color: #2e2e2e;
  }
  .button {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    button {
      background-color: #fe1c1d !important;
      width: 100%;
      margin: 0;
    }
  }
  .additional_info {
    grid-column-start: 1;
    grid-column-end: 3;
    textarea {
      width: 100%;
      min-height: 68px;
    }
  }
  input,
  textarea {
    color: #afafaf;
    padding: 5px 10px;
    max-height: 34px;
    background: #ffffff;
    border: 1px solid #afafaf;
    height: 34px;
    resize: none;
  }
}
@media screen and (max-width: 1200px) {
  .vuedal {
    form {
      padding: 30px;
    }
    .customer_view {
      grid-gap: 20px 15px;
    }
  }
}
</style>
<script>
import { validationMixin } from 'vuelidate';
import { Bus as VuedalsBus } from 'vuedals';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { validatePass } from '../utils/validators';
import DashboardsService from '../services/dashboards';

export default {
  name: 'CustomerViewModal',
  mixins: [validationMixin],
  props: {
    id: {
      type: [Number],
      default: null,
    },
    mode: {
      type: [String],
      default: null,
    },
    customer: {
      type: [Array, Object],
      default: null,
    },
    initData: {
      type: [Function],
      default: null,
    },
  },
  data: () => ({
    dashboardsService: new DashboardsService(),
    form: {
      customerName: null,
      password: null,
      repeatPassword: null,
      additional_info: null,
      newPassword: null,
    },
    isFormSubmited: false,
  }),
  validations() {
    if (this.mode === 'create') {
      return {
        form: {
          customerName: {
            required,
            maxLength: maxLength(64),
          },
          password: {
            required,
            maxLength: maxLength(16),
            minLength: minLength(8),
            validatePass,
          },
          additional_info: {
            maxLength: maxLength(512),
          },
        },
      };
    }
    return {
      form: {
        repeatPassword: {
          maxLength: maxLength(16),
          minLength: this.form.password ? minLength(8) : minLength(0),
          validatePass,
        },
        customerName: {
          required,
          maxLength: maxLength(64),
        },
        password: {
          maxLength: maxLength(16),
          minLength: this.form.password ? minLength(8) : minLength(0),
          validatePass,
        },
        additional_info: {
          maxLength: maxLength(512),
        },
      },
    };
  },
  beforeMount() {
    this.form.customerName = this.customer?.name;
    this.form.additional_info = this.customer?.info;
  },

  methods: {
    clearForm() {
      this.$v.$reset();
      this.form.userName = null;
      this.form.password = null;
    },
    close() {
      VuedalsBus.$emit('close', []);
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (!(fieldName === 'password' && this.mode === 'edit')) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
      return null;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid || this.mode === '') {
        const body = {
          ...this.customer,
          role: 'customer',
          name: this.form.customerName,
          info: this.form.additional_info ? this.form.additional_info : null,
          password: this.form.password,
        };
        if (this.mode === 'edit') {
          if (
            (!this.form.password && !this.form.repeatPassword) ||
            this.form.password === this.form.repeatPassword
          ) {
            if (!body.password) {
              delete body.password;
            }

            this.dashboardsService.editCustomer(body).subscribe(() => {
              this.initData();
              this.close();
            });
          }
        } else {
          this.dashboardsService.createCustomer(body).subscribe(() => {
            this.initData();
            this.close();
          });
        }
      }
    },
    submitForm() {},
  },
};
</script>
