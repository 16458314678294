<template>
  <div style="width: 100%">
    <header>
      <span v-if="currentUser">{{ currentUser.username }}</span>
      <img style="cursor: pointer" src="@/assets/logout_icon.svg" @click="logout()" />
    </header>
    <div class="accesList_body">
      <div class="card">
        <div><h1>Asset List</h1></div>
        <div class="search_container">
          <searchComponent
            :placeholder="'Search devices by name'"
            :options="devicesOptions"
            :background-color="'#' + 'f2f2f2'"
            @optionsChange="optionsChange"
          />
        </div>
        <div class="table">
          <div class="top_menu">
            <div
              v-for="item of sites"
              :key="item.id"
              :class="{ selected: currentListId === item.id }"
              @click="changeSite(item.id)"
            >
              <span>{{ item.name }}</span>
            </div>
            <div :class="{ selected: currentListId === null }" @click="getDevicesById(null)">
              <span>All</span>
            </div>
          </div>
          <div class="table_header">
            <span>Asset name</span>
          </div>
          <div class="table_body">
            <div v-for="item of devices" :key="item.id" class="table_body_row">
              <span @click="choseDivce(item.id)"> {{ item.assetName }}</span>
            </div>
            <div v-if="!devices || !(devices && devices.length)" class="noDataMonitorsContainer">
              <img src="@/assets/empty_monitors_icon.svg" />
              <span style="font-size: 16px"><strong>No added PD monitors</strong></span>
            </div>
          </div>
        </div>
        <div class="footer">
          <pageNavigator :list="devices" :options="devicesOptions" @optionsChange="optionsChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardsService from '../services/dashboards';
import ApiService from '../services/api';
import router from '../router';

export default {
  name: 'AssetList',
  data: () => ({
    currentListId: null,
    api: new ApiService(),
    dashboardsService: new DashboardsService(),
    sites: [],
    currentUser: null,
    devices: [],
    devicesOptions: { limit: 5, offset: 0, siteId: null },
  }),
  validations: {},

  created() {},
  beforeMount() {
    this.initData();
  },
  methods: {
    choseDivce(id) {
      router.push(`/assetItem?id=${id}`);
    },
    changeSite(id) {
      this.devicesOptions = { ...this.devicesOptions, offset: 0 };
      this.getDevicesById(id);
    },
    getDevicesById(id) {
      this.dashboardsService
        .getDevicesBySites({ ...this.devicesOptions, siteId: id })
        .subscribe((resp) => {
          this.devicesOptions = {
            ...this.devicesOptions,
            count: resp.data.message.count,
          };
          this.currentListId = id;
          const devices = resp.data.message.rows;
          this.devices = devices;
        });
    },

    logout() {
      this.api.logOut();
    },
    initData() {
      this.dashboardsService.getSites().subscribe((resp) => {
        const sites = resp.data.message.rows;
        sites.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });
        this.sites = sites;
        this.currentUser = this.$store.state?.user;
        this.getDevicesById(this.currentListId);
      });
    },
    optionsChange(e) {
      this.devicesOptions = { ...this.devicesOptions, ...e };
      this.getDevicesById(this.currentListId);
    },
  },
};
</script>

<style lang="scss" scoped>
.noDataMonitorsContainer {
  img {
    margin-bottom: 27px;
  }
  button {
    background-color: #fe1c1d !important;
    width: 155px;
    margin-top: 19px;
  }
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
header {
  background: #2e2e2e;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  color: white;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.2px;

  color: #fafafa;
}
.footer {
  height: 56px;
}
.table {
  height: calc(100% - 167px);
}
.selected {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: #252c32;
  border-bottom: 1px solid #252c32;
}
.top_menu {
  overflow: auto;
  div {
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  display: flex;
  gap: 0 30px;
  border-bottom: 1px solid #e4e7eb;
  padding: 10px 0;
}
.table_header {
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: -0.05px;

    color: #9ea0a5;
  }
}
.table_body_row {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.05px;

  color: #2e2e2e;
}
.table_body {
  position: relative;
  height: calc(100% - 100px);
  overflow: auto;
}
.table_body_row {
  cursor: pointer;
}
.table_header,
.table_body_row {
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
  }
  display: grid;
  height: 56px;
  grid-template-columns: 9fr 145px;
  border-bottom: 1px solid #e4e7eb;
  div {
    display: flex;
    align-items: center;
    padding: 0 60px 0 28px;
  }
}
.card {
  padding: 34px 40px;
  text-align: left;
}
.accesList_body {
  height: calc(100% - 80px);
  padding: 3%;
  .card {
    background: white;
    width: 100%;
    height: 100%;
  }
}
.search_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 41px 0 30px 0;
  .plus_symbol {
    font-size: 27px;
    top: 0px;
    left: -20px;
    position: absolute;
  }
  button {
    background-color: #fe1c1d !important;
    height: 36px;
    width: 180px;
    padding: 10px;
    text-align: right !important;
  }
  .md-button {
  }

  img {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  input {
    padding-left: 44px;
    background: #f2f2f2;
    box-shadow: 0px 0px 0px #e4e7eb;
    border-radius: 4px;
    border: 0;
    height: 36px;
    max-width: 620px;
    width: 620px;
  }
}
</style>
