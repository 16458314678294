<template>
  <div style="width: 100%">
    <header>
      <span v-if="currentDevice && currentDevice.customer">{{ currentDevice.customer.name }}</span>
      <img style="cursor: pointer" src="@/assets/logout_icon.svg" @click="logout()" />
    </header>
    <div class="assetItem_main">
      <div class="header">
        <img src="@/assets/arrow_back_icon.svg" @click="goBack()" />
        <h1 v-if="currentDevice">
          {{ currentDevice.assetName }}
        </h1>
      </div>
      <div class="body">
        <div class="a">
          <div class="graphTitle">
            <span> PRPD (Phase Resolved Partial Discharge) </span>
            <div class="graphDesignations">
              <div class="designationItem">
                <div class="designationBlack" />
                <span>PD Scale</span>
              </div>

              <div class="designationItem">
                <div class="designationRed" />
                <span>P1 (kV)</span>
              </div>
            </div>
          </div>

          <div class="graphPRPD">
            <div v-if="valueArray.length" class="YLabelsWrapper">
              <div v-for="(item, index) in valueArray" :key="index" class="YLabelsItem">
                <span>{{ item }}</span>
              </div>
            </div>
            <canvas id="canvas" />
          </div>
        </div>
        <div style="display: flex; flex-direction: column">
          <div class="asset_status">
            <div v-if="deviceStatus && deviceStatus !== 'none'" class="asset_status_header">
              <span>Health Condition</span>
            </div>
            <div v-if="deviceStatus && deviceStatus !== 'none'" class="asset_status_body">
              <div :class="deviceStatus">
                {{ deviceStatus }}
              </div>
            </div>
            <img
              v-if="!deviceStatus || deviceStatus === 'none'"
              style="width: 77px; margin: 0 auto"
              src="@/assets/empty_status_asset.svg"
            />
            <span
              v-if="!deviceStatus || deviceStatus === 'none'"
              style="font-size: 17px; margin-top: 16px"
              ><strong> No data available </strong></span
            >
          </div>
          <div class="asset_descrition">
            <div v-if="currentDevice && currentDevice.description" class="asset_descrition_header">
              Asset Description
            </div>
            <div v-if="currentDevice && currentDevice.description" class="asset_descrition_body">
              <span>
                {{ currentDevice.description }}
              </span>
            </div>
            <img
              v-if="!(currentDevice && currentDevice.description)"
              style="width: 86px; margin: 0 auto"
              src="@/assets/empty_description_icon.svg"
            />
            <span
              v-if="!(currentDevice && currentDevice.description)"
              style="font-size: 17px; margin-top: 16px; text-align: center; display: block"
              ><strong> No asset description </strong></span
            >
          </div>
        </div>
        <div class="b">
          <img v-if="imageUrl" :src="imageUrl" />
          <img
            v-if="!imageUrl"
            style="height: auto; width: auto"
            src="@/assets/empty_asset_image_icon.svg"
          />
        </div>
        <div class="c">
          <div id="map" />
        </div>
        <div class="d">
          <perTimeGraph
            v-if="currentDevice"
            :device="currentDevice"
            :options="options"
            @updatePacketDate="updatePacketDate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import DashboardsService from '../services/dashboards';
import ApiService from '../services/api';
import MonitorsService from '../services/monitors';
import router from '../router';
import 'chartjs-adapter-moment';

export default {
  name: 'AssetItem',
  data: () => ({
    moment: require('moment'),
    pocketData: null,
    imageUrl: null,
    map: null,
    currentDevice: null,
    deviceStatus: null,
    monitorsService: new MonitorsService(),
    api: new ApiService(),
    dashboardsService: new DashboardsService(),
    sites: [],
    valueArray: [],
    chart: null,
    types: new Map([
      ['Y', 'Year'],
      ['M', 'Month'],
      ['d', 'Day'],
      ['H', 'Hour'],
    ]),
    options: {},
  }),
  validations: {},
  mounted() {
    const deviceId = router.currentRoute.query.id;
    const options = this.getDateFromURL();
    this.getCurrentDevice(deviceId, options);
  },
  created() {},
  methods: {
    updatePacketDate(options) {
      this.valueArray = [];
      this.getPacketDate(options);
    },
    getPacketDate(options) {
      const { id } = router.currentRoute.query;
      this.monitorsService
        .getPacketData({
          deviceId: id,
          startDate: options.startDate,
          endDate: options.endDate,
          graphicType: 'PRPD',
        })
        .subscribe((resp) => {
          this.pocketData = resp.data.message;
          this.initPDCanvas();
          this.monitorsService.getHealthStatus(id).subscribe((respMonitor) => {
            this.deviceStatus = respMonitor.data.message.status;
          });
        });
    },
    getScale(maxNumber, base = 0.5) {
      const degree = 10 ** Math.floor(Math.log10(maxNumber));
      const normalized = maxNumber / degree;
      return base * Math.ceil(normalized / base) * degree;
    },
    initPDCanvas() {
      const canvas = document.getElementById('canvas');

      const eventNumbers = [];
      const labels = [];

      for (let i = 0; i < 360; i += 1) {
        labels.push(i);
        if (this.pocketData[i]) {
          eventNumbers.push(Math.floor(this.pocketData[i].magnitude));
        } else {
          eventNumbers.push(0);
        }
      }

      const sinX = [];
      const maxPow = Math.log10(Math.max(...eventNumbers));
      for (let i = 1; i <= 360; i += 1) {
        sinX.push(10 ** ((maxPow / 4) * Math.sin(i * (Math.PI / 180))) * 10 ** (maxPow / 2));
      }

      const planetChartData = {
        type: 'bar',

        data: {
          labels,
          datasets: [
            {
              label: 'Magnitude sum',
              data: eventNumbers,
              borderWidth: 1,
              order: 2,
            },
            {
              label: 'sin(x)',
              data: sinX,
              type: 'line',
              borderColor: 'rgb(254, 28, 29)',
              order: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 0,
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: true,
              type: 'logarithmic',
            },
          },
        },
      };

      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(canvas, planetChartData);
    },
    initialize() {
      const { latitude } = this.currentDevice;
      const { longitude } = this.currentDevice;
      const map = window.L.map('map').setView([+latitude, +longitude], 17);
      this.map = map;
      window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      const redIcon = new window.L.Icon({
        iconUrl: require('@/assets/pin_marker.svg'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      window.L.marker([+latitude, +longitude], { icon: redIcon }).addTo(map);
    },
    getCurrentDevice(id, options) {
      this.monitorsService.getMonitor(id).subscribe((resp) => {
        this.currentDevice = resp.data.message;
        this.initialize();
        this.getDeviceImage(id);
        this.getPacketDate({ ...options });
      });
    },
    getDeviceImage(id) {
      this.monitorsService.getMonitorImage(id).subscribe((resp) => {
        const arrayBufferView = new Uint8Array(resp.data);
        const blob = new Blob([arrayBufferView]);
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        this.imageUrl = imageUrl;
      });
    },
    getDateFromURL() {
      let date = this.moment();
      let type = 'Y';
      if (this.$route.query.date && Number.isInteger(+this.$route.query.date)) {
        date = this.moment(parseInt(this.$route.query.date, 10));
        if (this.types.has(this.$route.query.type)) {
          type = this.$route.query.type;
        }
      }

      const label = this.types.get(type);
      this.options.currentDate = date;
      this.options.currency = type;
      this.options.startDate = this.options.currentDate.startOf(label).toDate().getTime();
      this.options.endDate = this.options.currentDate.endOf(label).toDate().getTime();
      const result = {
        startDate: this.options.currentDate.startOf(label).toDate().getTime(),
        endDate: this.options.currentDate.endOf(label).toDate().getTime(),
      };

      return result;
    },
    goBack() {
      router.go(-1);
    },
    logout() {
      this.api.logOut();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.asset_status {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  padding: 20px 30px 27px 30px;

  .asset_status_header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1.13533px;
    text-align: left;
    color: #2e2e2e;
  }

  background-color: white;
}

#map {
  height: 100%;
}

.asset_descrition {
  overflow: auto;
  background-color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px 27px 30px;

  .asset_descrition_body {
    max-height: 100px;
    text-align: left;
    span {
      word-break: break-word;
      white-space: pre-line;
      padding-bottom: 27px;
    }
  }

  .asset_descrition_header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1.13533px;
    color: #2e2e2e;
    text-align: left;
  }
}

header {
  span {
    font-size: 14px;
    line-height: 20px;
  }
}

.asset_status_body {
  width: 100%;

  div {
    background: white;
    border: grey solid 1px;
    border-radius: 4.62px;
    color: balck;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 2px;
  }
}

.good {
  background: green !important;
  color: white !important;
  border: unset !important;
}

.warning {
  background: #ff8a00 !important;
  color: white !important;
  border: unset !important;
}

.critical {
  background: #fe1c1d !important;
  color: white !important;
  border: unset !important;
}

header {
  background: #2e2e2e;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  color: white;
}

.assetItem_main {
  padding: 0 50px;
  height: calc(100% - 80px);

  .body {
    display: grid;
    height: calc(100% - 120px);
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-rows: 50% 50%;
    grid-gap: 30px;
  }

  .a {
    display: flex;
    flex-direction: column;

    canvas {
      height: 100%;
      width: 100%;
      overflow: auto;
    }

    .graphDesignations {
      display: flex;

      .designationItem {
        margin-right: 20px;
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #9ea0a5;
        }
      }

      .designationBlack {
        width: 24px;
        height: 3px;
        background: #2e2e2e;
      }

      .designationRed {
        width: 24px;
        height: 3px;
        background: #fe1c1d;
      }
    }

    .graphTitle {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;

      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -1.13533px;
        color: #2e2e2e;
      }
    }

    .graphPRPD {
      display: flex;
      height: 100%;
      overflow: hidden;
    }

    .YLabelsWrapper {
      display: flex;
      flex-direction: column;
      height: 88%;
      margin-top: auto;
      margin-left: 10px;

      .YLabelsItem {
        margin-right: 5px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        span {
          font-family: 'Inter', sans-serif;
          color: #9ca3af;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  .b {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .c {
  }

  .b {
    padding: 10px;
  }

  .d {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .a,
  .a,
  .b,
  .c,
  .d {
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}

.header {
  display: flex;
  text-align: left;

  img {
    margin-right: 30px;
  }
}

@media screen and (max-width: 1150px) {
  .graphTitle {
    span {
      font-size: 10px !important;
    }
  }

  .designationItem {
    margin-right: 5px !important;
  }
}

@media screen and (max-height: 800px) {
  .asset_status {
    padding: 10px 15px 10px 15px;

    img {
      width: 51px !important;
    }

    span {
      font-size: 12px !important;
    }
  }
  .asset_descrition {
    padding: 10px 15px 10px 15px;

    img {
      width: 58px !important;
    }

    span {
      font-size: 12px !important;
    }
  }
}
</style>
