<template>
  <div class="customer_delete withoutCross">
    <div>
      <span v-if="customer"
        >Are you sure you want to delete <strong>{{ customer.assetName }}</strong
        >?
      </span>
    </div>
    <div class="buttons">
      <md-button
        style="background: #d8d8d8; color: black"
        class="md-raised md-primary"
        @click="close()"
      >
        Cancel
      </md-button>
      <md-button
        style="background-color: #fe1c1d"
        class="md-raised md-primary"
        @click="deleteDevice()"
      >
        Delete
      </md-button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.md-invalid {
  border: 1px solid #fe1c1d !important;
}
.settings_header {
  text-align: left;
}
.md-error {
  text-align: left;
  color: #fe1c1d;
}
.customer_delete {
  padding: 46px 74px;
  width: 487px;
  height: 206px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */
    letter-spacing: 0.15px;

    color: #2e2e2e;
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
}
// .close{
//     display: none;
// }
</style>
<script>
import { Bus as VuedalsBus } from 'vuedals';
import DashboardsService from '../services/dashboards';

export default {
  name: 'DeleteMonitor',

  props: {
    id: {
      type: [Number],
      default: null,
    },
    initData: {
      type: [Function],
      default: null,
    },
    customer: {
      type: [Array, Object],
      default: null,
    },
  },
  data: () => ({
    dashboardsService: new DashboardsService(),

    isFormSubmited: false,
  }),
  beforeMount() {},

  methods: {
    close() {
      this.initData();
      VuedalsBus.$emit('close', []);
    },
    deleteDevice() {
      this.dashboardsService.deleteDevice(this.id).subscribe(() => {
        this.close();
      });
    },
  },
};
</script>
