var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings_main md-layout-item md-size-45"},[_vm._m(0),_c('md-card',{staticClass:"md-layout-item md-size-100 settings_form_wrapper"},[_c('form',{staticClass:"md-layout md-layout-item settings_form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validateUser.apply(null, arguments)}}},[_c('div',{staticClass:"settings_body md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userName),expression:"form.userName"}],staticClass:"md-layout-item md-size-100",class:_vm.getValidationClass('userName'),attrs:{"id":"username","readonly":""},domProps:{"value":(_vm.form.userName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userName", $event.target.value)}}}),(!_vm.$v.form.userName.maxLength && _vm.$v.form.userName.$dirty)?_c('span',{staticClass:"md-error"},[_vm._v("Should have 64 characters")]):_vm._e()]),_c('div',{staticClass:"md-layout-item md-size-100",staticStyle:{"grid-column-start":"1"}},[_c('label',{attrs:{"for":"new_password"}},[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword),expression:"form.newPassword"}],staticClass:"md-layout-item md-size-100",class:_vm.getValidationClass('newPassword'),attrs:{"id":"new_password","type":"password"},domProps:{"value":(_vm.form.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "newPassword", $event.target.value)}}}),(
              _vm.form.newPassword === _vm.form.confirmPassword &&
              _vm.form.newPassword === _vm.form.currentPassword &&
              _vm.$v.$anyDirty
            )?_c('span',{staticClass:"md-error"},[_vm._v("Current and new password are the same")]):_vm._e(),(!_vm.$v.form.newPassword.required && _vm.$v.$anyDirty)?_c('span',{staticClass:"md-error"},[_vm._v("Required")]):_vm._e(),(
              _vm.$v.form.newPassword.$invalid &&
              _vm.$v.form.newPassword.$dirty &&
              _vm.$v.form.newPassword.required
            )?_c('span',{staticClass:"md-error"},[_vm._v("Should have 8-16 characters, alphabet, numeric and !#$%"),_c('span',[_vm._v(_vm._s('&'))]),_vm._v("*+-.?@_ symbols")]):_vm._e()]),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('label',{attrs:{"for":"confirm_password"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmPassword),expression:"form.confirmPassword"}],staticClass:"md-layout-item md-size-100",class:_vm.getValidationClass('confirmPassword'),attrs:{"id":"confirm_password","type":"password"},domProps:{"value":(_vm.form.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirmPassword", $event.target.value)}}}),(_vm.form.newPassword !== _vm.form.confirmPassword && _vm.$v.$anyDirty)?_c('span',{staticClass:"md-error"},[_vm._v("The confirmed password does not match with the input")]):_vm._e(),(
              _vm.$v.form.confirmPassword.$invalid &&
              _vm.$v.form.confirmPassword.$dirty &&
              _vm.$v.form.confirmPassword.required
            )?_c('span',{staticClass:"md-error"},[_vm._v("Should have 8-16 characters, alphabet, numeric and !#$%"),_c('span',[_vm._v(_vm._s('&'))]),_vm._v("*+-.?@_ symbols")]):_vm._e()]),_c('div',{staticClass:"md-layout-item md-size-100",staticStyle:{"grid-row-start":"2"}},[_c('label',{attrs:{"for":"current_password"}},[_vm._v("Current Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.currentPassword),expression:"form.currentPassword"}],staticClass:"md-layout-item md-size-100",class:_vm.getValidationClass('currentPassword'),attrs:{"id":"current_password","type":"password"},domProps:{"value":(_vm.form.currentPassword)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "currentPassword", $event.target.value)},function($event){_vm.isCurrentPassIncorrect = false}]}}),(_vm.isCurrentPassIncorrect)?_c('span',{staticClass:"md-error"},[_vm._v("Current password is incorrect")]):_vm._e(),(!_vm.$v.form.currentPassword.required && _vm.$v.$anyDirty)?_c('span',{staticClass:"md-error"},[_vm._v("Required")]):_vm._e(),(
              _vm.$v.form.currentPassword.$invalid &&
              _vm.$v.form.currentPassword.$dirty &&
              _vm.$v.form.currentPassword.required
            )?_c('span',{staticClass:"md-error"},[_vm._v("Should have 8-16 characters, alphabet, numeric and !#$%"),_c('span',[_vm._v(_vm._s('&'))]),_vm._v("*+-.?@_ symbols")]):_vm._e()]),_c('div',{staticStyle:{"grid-column-end":"3","grid-column-start":"1"}},[_c('md-button',{staticClass:"md-raised md-primary md-layout-item md-size-100",staticStyle:{"max-height":"40px","background-color":"#fe1c1d"},attrs:{"type":"submit"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" APPLY ")])],1)])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings_header"},[_c('h1',[_vm._v("Settings")])])}]

export { render, staticRenderFns }