<template>
  <div class="customer_modal">
    <div class="customer_header">
      <h1 v-if="mode === 'create'">Add New Asset</h1>
      <h1 v-if="mode === 'edit'">Edit Asset</h1>
    </div>
    <form @submit.prevent="validateUser">
      <div class="customer_view">
        <div class="customer_view_element" style="grid-column-start: 1; grid-row-start: 2">
          <label for="customer_name">Asset Name</label>
          <input
            id="customer_name"
            v-model="form.assetName"
            :class="getValidationClass('assetName')"
            type="text"
          />
          <span v-if="!$v.form.assetName.required && $v.form.$dirty" class="md-error"
            >Required</span
          >
          <span v-if="!$v.form.assetName.maxLength && $v.form.assetName.$dirty" class="md-error"
            >Should have 32 characters</span
          >
        </div>
        <div class="customer_view_element" style="grid-column-start: 1; grid-row-start: 5">
          <label for="select_file">Select site</label>
          <select
            id="select_file"
            v-model="form.site"
            :class="getValidationClass('site')"
            type="text"
          >
            <option v-for="item in sites" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.form.$dirty && $v.form.site.$invalid" class="md-error"
            >Required<br />
            Please select site</span
          >
        </div>
        <div class="customer_view_element" style="grid-column-start: 1; grid-row-start: 3">
          <label for="pd_monitors_id">PD Monitors ID</label>
          <input
            id="pd_monitors_id"
            v-model="form.monitor_id"
            :class="getValidationClass('monitor_id')"
            type="text"
            @input="isMacAdressUnique = true"
          />
          <span v-if="$v.form.monitor_id.$invalid && !$v.form.monitor_id.required" class="md-error"
            >Should have mac addres format</span
          >
          <span v-if="!isMacAdressUnique" class="md-error">PD Monitors ID should be unique</span>
        </div>
        <div class="customer_view_element" style="grid-column-start: 1; grid-row-start: 6">
          <label for="select_customers">Select Customers</label>
          <select
            id="select_customers"
            v-model="form.customer"
            :class="getValidationClass('customer')"
            type="text"
          >
            <option v-for="item in customers" :key="item.id" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.form.$dirty && $v.form.customer.$invalid" class="md-error"
            >Required<br />
            Please select customer</span
          >
        </div>
        <div class="geotag customer_view_element" style="grid-column-start: 1; grid-row-start: 4">
          <label for="geotag">Geotag</label>
          <div class="geotagInputs">
            <div>
              <input
                id="geotag"
                v-model="form.geotag_lat"
                :class="getValidationClass('geotag_lat')"
                placeholder="LAT"
                type="text"
              />
              <span v-if="!$v.form.geotag_lat.required && $v.form.$dirty" class="md-error"
                >Required</span
              >
              <span
                v-if="
                  ($v.form.geotag_lat.$invalid ||
                    $v.form.geotag_lon.$invalid ||
                    $v.form.geotag_ele.$invalid) &&
                  $v.form.geotag_lat.required &&
                  $v.form.$dirty
                "
                class="md-error"
                >Should have geotag form</span
              >
            </div>
            <div>
              <input
                id="geotag"
                v-model="form.geotag_lon"
                :class="getValidationClass('geotag_lon')"
                placeholder="LON"
                type="text"
              />
              <span v-if="!$v.form.geotag_lon.required && $v.form.$dirty" class="md-error"
                >Required</span
              >
            </div>
            <div>
              <input
                id="geotag"
                v-model="form.geotag_ele"
                :class="getValidationClass('geotag_ele')"
                placeholder="ELE (optional)"
                type="text"
              />
            </div>
          </div>
        </div>
        <div
          class="load_image customer_view_element"
          style="grid-column-start: 2; grid-row-start: 5"
        >
          <label>Upload Asset Picture<span class="optional_label"> (optional)</span></label>
          <div class="file_container">
            <div>
              <input
                id="picture"
                class="input_file"
                type="file"
                @change="chooseImage($event)"
                accept="image/png, image/bmp, image/gif, image/jpeg, image/svg+xml"
              />
              <label class="choose_file_icon" for="picture">Select File</label>
            </div>

            <div>
              <span v-if="!device && (!form.picture || (device && !device.imageId))"
                >No file selected</span
              >
              <span v-if="form.picture" class="file_name">{{ form.picture.name }} </span>
            </div>
          </div>
          <span class="md-error" v-if="this.form.picture && !isImage(this.form.picture.type)"
            >Unsupported image type</span
          >
        </div>

        <div class="customer_view_element_row" style="grid-column-start: 1; grid-row-start: 1">
          <label for="operation_mode">Operation mode</label>
          <md-switch v-model="form.operation_mode" />
        </div>

        <div class="customer_view_element" style="grid-column-start: 2; grid-row-start: 2">
          <label for="detection_threshold"
            >Detection Threshold<span class="optional_label"> (optional)</span></label
          >
          <input
            id="detection_threshold"
            v-model="form.detection_threshold"
            type="number"
            min="0"
          />
        </div>

        <div class="customer_view_element" style="grid-column-start: 2; grid-row-start: 3">
          <label for="packet_timeout"
            >Packet timeout, sec<span class="optional_label"> (optional)</span></label
          >
          <input id="packet_timeout" v-model="form.packet_timeout" type="number" min="0" />
        </div>

        <div class="customer_view_element" style="grid-column-start: 2; grid-row-start: 4">
          <label for="max_number_of_PDs"
            >Max number of PDs<span class="optional_label"> (optional)</span>
          </label>
          <input id="max_number_of_PDs" v-model="form.max_number_of_PDs" type="number" min="0" />
        </div>

        <div class="additional_info customer_view_element" style="grid-row-start: 7">
          <label for="additional_info"
            >Description<span class="optional_label"> (optional)</span></label
          >
          <textarea
            id="additional_info"
            v-model="form.description"
            :class="getValidationClass('description')"
            type="text"
          />
          <span v-if="!$v.form.description.maxLength && $v.form.description.$dirty" class="md-error"
            >Your input should be less than 512 symbols</span
          >
        </div>
        <div class="button customer_view_element" style="grid-row-start: 8">
          <md-button
            v-if="mode === 'create'"
            :disabled="isSubmitBtnDisabled"
            type="submit"
            class="md-raised md-primary"
          >
            Create
          </md-button>

          <md-button v-if="mode === 'edit'" class="md-raised md-primary cancel" @click="close()">
            Cancel
          </md-button>
          <md-button
            v-if="mode === 'edit'"
            :disabled="isSubmitBtnDisabled"
            type="submit"
            class="md-raised md-primary"
          >
            Save
          </md-button>
        </div>
      </div>
    </form>
  </div>
</template>
<style scoped lang="scss">
.load_image {
  position: relative;
  .file_container {
    .file_name {
      max-width: 150px;
      word-break: break-word;
    }
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .input_file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  .choose_file_icon {
    height: 34px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #d8d8d8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  input {
    position: absolute;
    bottom: 0;
    opacity: 0;
  }
}
input,
select {
  margin-bottom: 5px !important;
}
.md-invalid {
  border: 1px solid #fe1c1d !important;
}
.settings_header {
  text-align: left;
}
.md-error {
  text-align: left;
  color: #fe1c1d;
}
.geotag {
  .geotagInputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  div {
    flex-direction: row !important;
    justify-content: space-between;
    div {
      display: grid !important;
    }
    div {
    }
    input {
      width: 100%;
    }
  }
}
form {
  .customer_view {
    height: 100%;
  }
}
.customer_view {
  display: grid;
  grid-gap: 10px 45px;

  grid-template-columns: 47% 47%;
  .customer_view_element {
    text-align: left;
    display: flex;
    flex-direction: column;
    .optional_label {
      color: #afafaf;
    }
  }
  .customer_view_element_row {
    display: flex;
    align-items: center;
    .md-switch {
      --md-theme-default-accent: #f5f5f5;
      --md-theme-default-accent-on-: #fe1c1d;
      margin: -10px 0 0 10px;
    }
  }
  label,
  select {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background: white;
    letter-spacing: 0.15px;
    color: #2e2e2e;
  }
  label {
    margin-bottom: 9px !important;
  }
  select {
    height: 34px;
  }
  .button {
    grid-column-start: 1;
    grid-column-end: 3;
    justify-content: center;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    .cancel {
      background-color: #d8d8d8 !important;
      margin-right: 30px;
      color: #4b4b4b !important;
    }
    button {
      background-color: #fe1c1d !important;
      width: 100%;
      margin: 0;
    }
  }
  .additional_info {
    grid-column-start: 1;
    grid-column-end: 3;
    textarea {
      width: 100%;
      min-height: 68px;
      color: #afafaf;
      padding: 5px 10px;
      max-height: 34px;
      background: #ffffff;
      border: 1px solid #afafaf;
      resize: none;
    }
  }
  input {
    color: #afafaf;
    padding: 5px 10px;
    max-height: 34px;
    background: #ffffff;
    border: 1px solid #afafaf;
    height: 34px;
  }
}
label {
  margin-bottom: 0 !important;
}
.customer_modal {
  padding: 50px;
  max-height: 80vh;
  width: 50vw;
  overflow: auto;
  .customer_header {
    text-align: left;
    h1 {
      margin-bottom: 15px;
      margin-top: 0;
    }
  }
}
@media screen and (max-width: 1200px) {
  form {
    .customer_modal {
      padding: 30px;
    }
    .customer_view {
      grid-gap: 10px 15px;
    }
    .customer_view_element {
      label {
        font-size: 14px;
      }
    }
  }
}
</style>
<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  maxLength,
  macAddress,
  // alpha,
} from 'vuelidate/lib/validators';
import { Bus as VuedalsBus } from 'vuedals';
import DashboardsService from '../services/dashboards';

const validateGeaTag = (value) => {
  if (value) {
    return !value.replace(/-?[0-9]{1,3}[.][0-9]+/, '');
  }
  return true;
};
export default {
  name: 'MonitorViewModal',
  mixins: [validationMixin],
  props: {
    id: {
      type: [Number],
      default: null,
    },
    mode: {
      type: [String],
      default: null,
    },
    device: {
      type: [Array, Object],
      default: null,
    },
    initData: {
      type: [Function],
      default: null,
    },
  },
  data: () => ({
    sites: [{ name: null }],
    customers: [{ name: null }],
    dashboardsService: new DashboardsService(),
    form: {
      assetName: null,
      site: null,
      monitor_id: null,
      customer: null,
      geotag_lat: null,
      geotag_lon: null,
      geotag_ele: null,
      picture: null,
      description: null,
      operation_mode: null,
      detection_threshold: null,
      packet_timeout: null,
      max_number_of_PDs: null,
    },
    isFormSubmited: false,
    isSubmitBtnDisabled: false,
    isMacAdressUnique: true,
    allowedTypes: ['image/png', 'image/bmp', 'image/gif', 'image/jpeg', 'image/svg+xml'],
  }),
  validations: {
    form: {
      assetName: {
        required,
        // alpha,
        maxLength: maxLength(32),
      },
      site: {
        required,
      },
      monitor_id: {
        macAddress: macAddress(),
      },
      customer: {
        required,
      },
      geotag_lat: {
        validateGeaTag,
        minLength: minLength(3),
        required,
      },
      geotag_lon: {
        validateGeaTag,
        minLength: minLength(3),
        required,
      },
      geotag_ele: {
        validateGeaTag,
      },
      picture: {},
      description: {
        maxLength: maxLength(512),
      },
    },
  },
  methods: {
    chooseImage(e) {
      this.form.picture = e.target.files[0];
    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
      return null;
    },
    clearForm() {
      this.$v.$reset();
      this.form.userName = null;
      this.form.password = null;
    },
    close() {
      this.isSubmitBtnDisabled = false;
      VuedalsBus.$emit('close', []);
    },
    getInt(value) {
      return value?.toString().length ? +value : null;
    },
    isImage(filetype) {
      return this.allowedTypes.includes(filetype);
    },
    validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSubmitBtnDisabled = true;
        let config = this.device?.config;

        if (
          this.form.operation_mode ||
          this.form.detection_threshold ||
          this.form.packet_timeout ||
          this.form.max_number_of_PDs ||
          !!config
        ) {
          config = {
            operationMode: this.form.operation_mode,
            detectionTreshold: this.getInt(this.form.detection_threshold),
            pdPacketTimeout: this.getInt(this.form.packet_timeout),
            maxPdsPerPacket: this.getInt(this.form.max_number_of_PDs),
          };
        }
        const body = {
          assetName: this.form.assetName,
          siteId: this.form.site,
          PDMonitorId: this.form.monitor_id ? this.form.monitor_id : null,
          customerId: this.form.customer,
          latitude: this.form.geotag_lat,
          longitude: this.form.geotag_lon,
          elevation: this.form.geotag_ele ? this.form.geotag_ele : null,
          description: this.form.description ? this.form.description : null,
          config,
        };

        const createuser = () => {
          if (!this.form.picture || this.isImage(this.form?.picture?.type)) {
            this.dashboardsService.createDevice(body).subscribe(
              (resp) => {
                if (this.form.picture) {
                  this.dashboardsService
                    .loadImageDevice(resp.data.message.id, this.form.picture)
                    .subscribe(
                      () => {
                        this.close();
                        this.initData();
                      },
                      () => {
                        this.isSubmitBtnDisabled = false;
                      }
                    );
                } else {
                  this.close();
                  this.initData();
                }
              },
              (error) => {
                this.isSubmitBtnDisabled = false;
                if (error.data.status === 500) {
                  this.isMacAdressUnique = false;
                }
              }
            );
          } else {
            this.isSubmitBtnDisabled = false;
          }
        };
        const updateUser = () => {
          const updateBody = { ...this.device, ...body };

          if (updateBody.config) {
            updateBody.config.deviceId = updateBody.id;
          }
          if (!this.form.picture || this.isImage(this.form?.picture?.type)) {
            this.dashboardsService.updateDevice(updateBody).subscribe(
              (resp) => {
                if (this.form.picture) {
                  this.dashboardsService
                    .updateImageDevice(resp.data.message.id, this.form.picture)
                    .subscribe(
                      () => {
                        this.close();
                        this.initData();
                      },
                      () => {
                        this.isSubmitBtnDisabled = false;
                      }
                    );
                } else {
                  this.isSubmitBtnDisabled = false;
                  this.close();
                  this.initData();
                }
              },
              (error) => {
                this.isSubmitBtnDisabled = false;
                if (error.data.status === 500) {
                  this.isMacAdressUnique = false;
                }
              }
            );
          } else {
            this.isSubmitBtnDisabled = false;
          }
        };

        if (this.mode === 'create') {
          createuser();
        } else {
          updateUser();
        }
      }
    },
    submitForm() {},
  },
  beforeMount() {
    this.dashboardsService.getUsers().subscribe((resp) => {
      const users = resp.data.message.rows;
      const customers = [];
      users.forEach((item) => {
        if (item.role === 'customer') {
          customers.push(item);
        }
      });
      this.customers = customers;
      this.dashboardsService.getSites().subscribe((respDashboard) => {
        this.sites = respDashboard.data.message.rows;
      });
    });

    this.form.assetName = this.device?.assetName;
    this.form.site = this.device?.siteId;
    this.form.monitor_id = this.device?.PDMonitorId;
    this.form.customer = this.device?.customerId;
    this.form.geotag_lat = this.device?.latitude;
    this.form.geotag_lon = this.device?.longitude;
    this.form.geotag_ele = this.device?.elevation;
    this.form.description = this.device?.description;

    this.form.operation_mode = !!this.device?.config?.operationMode;
    this.form.detection_threshold = this.device?.config?.detectionTreshold;
    this.form.packet_timeout = this.device?.config?.pdPacketTimeout;
    this.form.max_number_of_PDs = this.device?.config?.maxPdsPerPacket;
  },
};
</script>
