<template>
  <div v-if="isShowSideBar" class="sideBar_main">
    <div class="sideBar_header">
      <img class="login_logo" src="@/assets/login_logo.svg" />
    </div>
    <div class="sideBar_body">
      <router-link
        style="color: unset"
        v-bind:to="'/customers'"
        v-bind:class="{ active: pageName === 'customers' }"
        class="sideBar_element"
      >
        <img src="@/assets/customers_sidebar_icon.svg" />
        <span>Customers</span>
      </router-link>
      <router-link
        style="color: unset"
        v-bind:to="'/monitors'"
        v-bind:class="{ active: pageName === 'monitors' }"
        class="sideBar_element"
      >
        <img src="@/assets/pdMonitors_sidebar_icon.svg" />
        <span>PD Monitors</span>
      </router-link>

      <hr />
      <router-link
        style="color: unset"
        v-bind:to="'/settings'"
        v-bind:class="{ active: pageName === 'settings' }"
        class="sideBar_element"
      >
        <img src="@/assets/setting_sidebar_icon.svg" />
        <span>Settings</span>
      </router-link>

      <button v-on:click="logOut()" class="sideBar_element">
        <img src="@/assets/logout_icon.svg" /><span>Logout</span>
      </button>
    </div>
  </div>
</template>

<script>
import router from '../router';
import ApiService from '../services/api';

export default {
  name: 'SiderBar',
  props: {
    newsletterPopup: {},
  },
  data: () => ({
    isShowSideBar: false,
    pageName: String,
    api: new ApiService(),
  }),

  beforeMount() {
    this.pageName = router.currentRoute.name;
    this.isShowSideBar = this.$store.state.routesParams[this.pageName]?.isShowSideBar;

    router.beforeEach((to, from, next) => {
      this.isShowSideBar = this.$store.state.routesParams[to.name]?.isShowSideBar;
      this.pageName = to.name;
      next();
    });
  },
  methods: {
    logOut() {
      this.api.logOut();
    },
  },
};
</script>

<style lang="scss">
.sideBar_main {
  max-width: 300px;
  min-width: 300px;
  height: 100%;
  background: #2e2e2e;
}
.sideBar_header {
  margin: 50px 50px 167px 50px;
}
.sideBar_body {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: rgba(242, 242, 242, 0.3);
  text-align: left;
  hr {
    height: 1px;
    margin-top: 84px;
    border-color: rgba(242, 242, 242, 0.3);
    border-width: 0.3px !important;
  }
}
.active {
  background: rgba(159, 162, 180, 0.02);
  border-left: 1px solid #fe1c1d;
  color: white !important;
  img {
    opacity: 1 !important;
  }
}

.sideBar_element {
  &:hover {
    background: rgba(159, 162, 180, 0.02) !important;
    border-left: 1px solid #fe1c1d !important;
    span {
      color: white !important;
    }
    img {
      opacity: 1 !important;
    }
  }
  padding: 20px 50px;
  display: flex;
  align-items: center;
  width: 100%;
  img {
    margin-right: 24px !important;
    opacity: 0.3;
  }
  span {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: rgba(242, 242, 242, 0.3);
  }
}
</style>
