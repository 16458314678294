<template>
  <div class="customer_delete withoutCross">
    <div>
      <span
        >Are you sure you want to delete <strong v-if="customer">{{ customer.name }}</strong
        >?</span
      >
    </div>
    <div class="buttons">
      <md-button
        style="background: #d8d8d8; color: black"
        class="md-raised md-primary"
        @click="close()"
      >
        Cancel
      </md-button>
      <md-button
        style="background-color: #fe1c1d"
        class="md-raised md-primary"
        @click="deleteCustomer()"
      >
        Delete
      </md-button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.md-invalid {
  border: 1px solid #fe1c1d !important;
}
.settings_header {
  text-align: left;
}
.md-error {
  text-align: left;
  color: r#FE1C1Ded;
}
.customer_delete {
  padding: 46px 74px;
  width: 487px;
  height: 206px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */
    letter-spacing: 0.15px;

    color: #2e2e2e;
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
}
// .close{
//     display: none;
// }
</style>
<script>
import { validationMixin } from 'vuelidate';
import { Bus as VuedalsBus } from 'vuedals';
import { required, minLength, maxLength, alpha } from 'vuelidate/lib/validators';
import { validatePass } from '../utils/validators';
import DashboardsService from '../services/dashboards';

export default {
  name: 'DeleteCustomer',
  mixins: [validationMixin],
  props: {
    id: {
      type: [Number],
      default: null,
    },
    initData: {
      type: [Function],
      default: null,
    },
    customer: {
      type: [Array, Object],
      default: null,
    },
  },
  data: () => ({
    dashboardsService: new DashboardsService(),
    form: {
      customerName: null,
      password: null,
      additional_info: null,
    },
    isFormSubmited: false,
  }),
  validations: {
    form: {
      customerName: {
        required,
        alpha,
        maxLength: maxLength(20),
        minLength: minLength(5),
      },
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(8),
        validatePass,
      },
      additional_info: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(8),
      },
    },
  },
  beforeMount() {
    this.form.customerName = this.customer.name;
  },

  methods: {
    close() {
      VuedalsBus.$emit('close', []);
    },
    deleteCustomer() {
      this.dashboardsService.deleteCustomer(this.id).subscribe(() => {
        this.initData();
        this.close();
      });
    },

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
      return null;
    },
    clearForm() {
      this.$v.$reset();
      this.form.userName = null;
      this.form.password = null;
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    },
    submitForm() {},
  },
};
</script>
