/* eslint-disable import/prefer-default-export */

import { helpers } from 'vuelidate/lib/validators'

const patterns = {
    'numeric': '0-9',
    'latin': 'A-z',
    'special': '!#$%&*+-.?@_',
}

function makePassRegex() {
    let regex = '';
    const rules = [];

    Object.keys(patterns).forEach((rule) => {
        rules.push(patterns[rule])
        regex += `(?=.*[${patterns[rule]}])`
    })

    regex += `[${rules.join('')}]+`;
    return new RegExp(regex, 'g');
}

export function validatePass(password) {
    let result = false;
    const regex = makePassRegex()
    if (password && regex.test(password)) {
        result = true;
    }
    return !helpers.req(password) || result;
};
