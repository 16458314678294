<template>
  <div class="perTimeGraph_main">
    <div class="datePicker_container">
      <div class="datePicker_activities">
        <div class="datePicker_element">
          <div v-if="this.currentDate" class="label">
            Number of PDs per
            {{ this.types.get(this.currency).separate }}
          </div>

          <div v-if="this.currentDate && !isRange">
            <span v-if="this.currency === 'Y'">{{ this.currentDate.format('YYYY') }}</span>
            <span v-if="this.currency === 'M'">{{ this.currentDate.format('MMMM YYYY') }}</span>
            <span v-if="this.currency === 'd'">{{ this.currentDate.format('MMMM Do YYYY') }}</span>
            <span v-if="this.currency === 'H'">{{
              this.currentDate.format('MMMM Do YYYY, h a')
            }}</span>
          </div>
        </div>
        <div class="datePicker_element">
          <div style="display: flex; align-items: center">
            <datetime
              v-if="isRange"
              v-model="selectedDate.start"
              :min-datetime="datePickerOptions.minFrom"
              :max-datetime="datePickerOptions.maxFrom"
              :type="datePickerType"
              input-format="DD-MM-YYYY"
              placeholder="Select date from"
              class="theme-red"
            >
            </datetime>
            <datetime
              v-if="isRange"
              v-model="selectedDate.end"
              :min-datetime="datePickerOptions.minTo"
              :max-datetime="datePickerOptions.maxTo"
              :type="datePickerType"
              input-format="DD-MM-YYYY"
              placeholder="Select date to"
              class="theme-red"
            >
            </datetime>
            <button
              class="range_button"
              v-bind:class="{ active_range: isRange }"
              v-on:click="onRange()"
            >
              Date/Time Range
            </button>
          </div>
          <div class="pciker">
            Show:
            <select
              v-model="currency"
              @change="changeCurrency()"
              style="text-transform: capitalize"
            >
              <option v-for="key in types.keys()" :value="key" :key="key">
                {{ types.get(key).value }}
              </option>
            </select>
          </div>
          <div v-if="!isRange" class="arrows">
            <button @click="moveDate(-1)">
              <img src="@/assets/arrow.svg" />
            </button>
            <button @click="moveDate(1)">
              <img class="arrows_right" src="@/assets/arrow.svg" />
            </button>
          </div>
        </div>
      </div>
      <div class="datePicker_items" v-if="this.currentDate">
        <button
          class="item"
          v-for="item in currencyItemsArray"
          :key="item.number"
          @click="chooseDate(item)"
        >
          <span v-if="currency == 'Y'"> {{ item.number + 1 }}</span>
          <span v-else> {{ item.number }}</span>
          <span v-if="currency == 'M'" class="additional_label">
            {{ item.currentDate.format('ddd') }}
          </span>
        </button>
      </div>
    </div>
    <div class="graph_container">
      <canvas id="canvas_pds" />
    </div>
    <loader
      v-if="isSpinerActive"
      object="#FE1C1D"
      color1="#ffffff"
      color2="#17fd3d"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="80"
      disable-scrolling="false"
      name="spinning"
    />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import MonitorsService from '../services/monitors';
import 'chartjs-adapter-moment';

export default {
  name: 'PerTimeGraph',
  props: {
    device: null,
    initData: null,
    options: null,
  },
  data: () => ({
    monitorsService: new MonitorsService(),
    types: new Map([
      [
        'Y',
        {
          value: 'year',
          separate: 'month',
          detalization: 'day',
          format: 'MMMM Do YYYY',
          next: 'M',
          datePickerType: 'date',
        },
      ],
      [
        'M',
        {
          value: 'month',
          separate: 'day',
          detalization: 'hour',
          format: 'MMMM Do YYYY, HH',
          next: 'd',
          datePickerType: 'date',
        },
      ],
      [
        'd',
        {
          value: 'day',
          separate: 'hour',
          detalization: 'minute',
          format: 'MMMM Do YYYY, HH:mm',
          next: 'H',
          datePickerType: 'datetime',
        },
      ],
      [
        'H',
        {
          value: 'hour',
          separate: 'minute',
          detalization: 'second',
          format: 'MMMM Do YYYY, HH:mm:ss',
          next: 'H',
          datePickerType: 'time',
        },
      ],
    ]),
    moment: require('moment'),
    currentDate: null,
    isSpinerActive: true,
    currency: 'Y',
    currencyItemsArray: [],
    canvasDatesArray: [],
    pocketData: [],
    chart: null,
    data: [],
    max: 10,
    isRange: false,
    selectedDate: {
      start: null,
      end: null,
    },
    datePickerOptions: {
      minFrom: null,
      maxFrom: null,
      minTo: null,
      maxTo: null,
    },
    startDate: null,
    endDate: null,
  }),
  computed: {
    datePickerType: function () {
      return this.types.get(this.currency).datePickerType;
    },
  },
  watch: {
    selectedDate: {
      handler: function (date) {
        if (date.start) {
          this.datePickerOptions.minTo = this.moment(date.start).toISOString();
          this.datePickerOptions.maxTo = this.moment(date.start)
            .add(1, this.types.get(this.currency).value)
            .toISOString();
        } else {
          this.datePickerOptions.minFrom = this.moment(date.end)
            .subtract(1, this.types.get(this.currency).value)
            .toISOString();
          this.datePickerOptions.maxFrom = this.moment(date.end).toISOString();
        }
        if (date.start && date.end) {
          this.getPocketdata({
            startDate: this.moment(date.start).toDate().getTime(),
            endDate: this.moment(date.end).toDate().getTime(),
          });
          this.selectedDate = {
            start: null,
            end: null,
          };
        }
      },
      deep: true,
    },
    initData(newVal) {
      this.pocketData = newVal;
      this.initiDatePicker();
    },
  },
  validations: {},
  updated() {},
  mounted() {
    if (this.options) {
      this.currentDate = this.moment(this.options.currentDate);
      this.currency = this.options.currency;
    } else {
      this.currentDate = this.moment();
    }
    this.getPocketdata(false);
  },
  methods: {
    updatePacketDate(options) {
      this.$emit('updatePacketDate', options);
    },
    changeCurrency() {
      this.clearRange();
      this.getPocketdata();
    },
    clearRange() {
      this.selectedDate.start = null;
      this.selectedDate.end = null;
      this.datePickerOptions.minFrom = null;
      this.datePickerOptions.maxFrom = null;
      this.datePickerOptions.minTo = null;
      this.datePickerOptions.maxTo = null;
      this.isRange = false;
    },

    onRange() {
      if (this.isRange) {
        this.clearRange();
        this.getPocketdata();
      } else {
        this.isRange = true;
      }
    },
    updateData() {
      const data = [];
      const days = [];
      const options = {
        start: this.types.get(this.currency).value,
        separate: this.types.get(this.currency).detalization,
      };

      const label = this.types.get(this.currency).separate;
      const dateStart = this.moment(this.startDate).startOf(label);
      const dateEnd = this.moment(this.endDate).endOf(label);
      while (dateEnd.diff(dateStart, options.separate) >= 0) {
        days.push(dateStart.toDate());
        dateStart.add(1, options.separate);
      }

      let max = 0;
      let prevValue;
      let nextValue = 0;
      this.canvasDatesArray.forEach((value, index) => {
        if (value > max) {
          max = value;
        }
        nextValue = this.canvasDatesArray[index + 1];
        data[index] = { y: value, x: days[index] };
        if (!value && (prevValue || nextValue)) {
          data[index] = { y: 0.1, x: days[index] };
        }
        prevValue = value;
      });
      this.max = 10 ** Math.ceil(Math.log10(max));
      if (max === this.max) {
        this.max += this.max / 10;
      }
      this.data = data;
    },
    initPDCanvas() {
      const canvas = document.getElementById('canvas_pds');
      this.updateData();

      const chartAreaBorder = {
        id: 'chartAreaBorder',
        beforeDraw(chart, args, options) {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = chart;
          ctx.save();
          ctx.strokeStyle = options.borderColor;
          ctx.lineWidth = options.borderWidth;
          ctx.setLineDash(options.borderDash || []);
          ctx.lineDashOffset = options.borderDashOffset;
          ctx.strokeRect(left, top, width, height);
          ctx.restore();
        },
      };

      const planetChartData = {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Number of PDs',
              data: this.data,
              backgroundColor: 'rgba(54,73,93,.5)',
              borderColor: '#36495d',
              borderWidth: 1,
              fill: { value: 0 },
            },
          ],
        },
        options: {
          plugins: {
            chartAreaBorder: {
              borderColor: '#E5E7EB',
              borderWidth: 1,
            },
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 2,
            },
          },
          scales: {
            x: {
              grid: {
                color: '#E5E7EB',
                drawBorder: false,
                drawTicks: false,
              },
              type: 'time',
              time: {
                tooltipFormat: 'MM-DD-YYYY',
                unit: 'month',
              },
              ticks: {
                display: false,
              },
            },

            y: {
              grid: {
                color: '#E5E7EB',
                drawBorder: false,
                drawTicks: false,
              },
              display: true,
              min: 1,
              max: this.max,
              type: 'logarithmic',
              ticks: {
                padding: 10,
                callback(value) {
                  if (Number.isInteger(Math.log10(value))) {
                    return value.toExponential();
                  }
                  return null;
                },
              },
            },
          },
        },
        plugins: [chartAreaBorder],
      };
      planetChartData.options.scales.x.time.unit = this.types.get(this.currency).separate;
      planetChartData.options.scales.x.time.tooltipFormat = this.types.get(this.currency).format;
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(canvas, planetChartData);
      this.isSpinerActive = false;
    },
    getPocketdata(updateDate = true) {
      this.isSpinerActive = true;

      const label = this.types.get(this.currency).value;

      let start = this.moment(this.currentDate).startOf(label);
      let end = this.moment(this.currentDate).endOf(label);

      if (this.selectedDate.start && this.selectedDate.end) {
        start = this.moment(this.selectedDate.start);
        end = this.moment(this.selectedDate.end);
        if (this.currency === 'H') {
          start.set({
            year: this.currentDate.year(),
            month: this.currentDate.month(),
            date: this.currentDate.date(),
          });
          end.set({
            year: this.currentDate.year(),
            month: this.currentDate.month(),
            date: this.currentDate.date(),
          });
        }
      }

      const options = {
        deviceId: this.device.id,
        graphicType: 'MAX_PDS',
        startDate: start.toDate().getTime(),
        endDate: end.toDate().getTime(),
        detalization: this.types.get(this.currency).detalization.toUpperCase(),
      };
      this.$router.replace({
        query: { id: this.device.id, date: options.startDate, type: this.currency },
      });
      this.monitorsService.getPacketData({ ...options }).subscribe((resp) => {
        this.pocketData = resp.data.message;
        this.initiDatePicker(options);
        if (updateDate) {
          this.updatePacketDate(options);
        }
      });
    },
    moveDate(amount) {
      this.currentDate.add(amount, this.currency);
      this.getPocketdata();
    },
    chooseDate(item) {
      this.currentDate = this.moment(item.currentDate);
      this.currency = this.types.get(this.currency).next;
      this.clearRange();
      this.getPocketdata();
    },
    initiDatePicker(options) {
      this.canvasDatesArray = [];
      this.currencyItemsArray = [];
      this.startDate = options.startDate;
      this.endDate = options.endDate;
      const label = this.types.get(this.currency).separate;
      const start = this.moment(options.startDate).startOf(label);
      const end = this.moment(options.endDate).startOf(label);
      const temp = new Map();

      while (end.diff(start, label) >= 0) {
        let number = null;
        const currentDate = this.moment(start);
        if (this.currency === 'Y') {
          number = start.month();
          temp.set(start.format('YYYY-MM'), new Array(start.daysInMonth()).fill(0));
        } else if (this.currency === 'M') {
          number = start.date();
          temp.set(start.format('YYYY-MM-DD'), new Array(24).fill(0));
        } else if (this.currency === 'd') {
          number = start.hour();
          temp.set(start.format('YYYY-MM-DD-HH'), new Array(60).fill(0));
        } else if (this.currency === 'H') {
          number = start.minute();
          temp.set(start.format('YYYY-MM-DD-HH-mm'), new Array(60).fill(0));
        }

        this.currencyItemsArray.push({ number, currentDate });
        start.add(1, label);
      }
      const keys = Object.keys(this.pocketData);
      for (let i = 0; i < keys.length; i += 1) {
        const el = this.pocketData[keys[i]];
        const date = this.moment(el.time);
        if (this.currency === 'Y') {
          temp.get(date.format('YYYY-MM'))[date.date() - 1] = el.pdData.numberOfPd;
        } else if (this.currency === 'M') {
          temp.get(date.format('YYYY-MM-DD'))[date.hour()] = el.pdData.numberOfPd;
        } else if (this.currency === 'd') {
          temp.get(date.format('YYYY-MM-DD-HH'))[date.minute()] = el.pdData.numberOfPd;
        } else if (this.currency === 'H') {
          temp.get(date.format('YYYY-MM-DD-HH-mm'))[date.second() - 1] = el.pdData.numberOfPd;
        }
      }

      let array = [];
      temp.forEach(function (value) {
        array = array.concat(value);
      });
      this.canvasDatesArray = array;
      this.initPDCanvas();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

canvas {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.perTimeGraph_main {
  position: relative;

  height: 100%;
}

.datePicker_container,
.graph_container {
  padding: 0 25px;
}

.graph_value {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  position: relative;
}

.graph_value_item:not(:first-child) {
  margin-right: 5px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.graph_value_item:first-child {
  position: absolute;
  right: 6px;
  top: -18px;
}

.graph_value_item {
  span {
    font-family: 'Inter', sans-serif;
    color: #9ca3af;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-height: 800px) {
  .graph_value_item {
    span {
      font-size: 10px;
      line-height: 5px;
    }
  }
}

select {
  background-color: unset !important;
  border: unset !important;
}

.graph_container {
  display: flex;
  height: calc(100% - 140px);
}

.datePicker_items {
  height: 60px;
  align-items: center;
  display: flex;
  width: calc(100% - 52px);
  margin-left: 47px;
  .item {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .additional_label {
      color: #afafaf;
      font-style: normal;
      font-weight: normal;
      font-size: 14.0942px;
      line-height: 23px;
      letter-spacing: -0.512518px;
    }
    &:hover {
      span {
        background-color: #fe1c1d;
        color: white;
        cursor: pointer;
      }
    }

    span {
      font-size: 14px;
      font-weight: 600;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.datePicker_activities {
  padding: 32px 40px 0 40px;
  display: flex;
  justify-content: space-between;

  .datePicker_element {
    display: flex;
    align-items: center;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -1.13533px;
      color: #2e2e2e;
    }
  }

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1.13533px;
    color: #2e2e2e;
    margin-right: 30px;
  }

  .additionalLabel {
    font-size: 13px;
    line-height: 10px;
    color: #afafaf;
  }

  .pciker {
    margin-right: 30px;
  }

  .arrows {
    height: 25px;
    display: flex;
    width: 100px;

    img {
      height: 100%;
    }
    .arrows_right {
      transform: rotate(180deg);
    }

    justify-content: space-between;
  }
}

@media screen and (max-width: 1150px) {
  .datePicker_activities {
    .label {
      font-size: 14px;
    }

    .additionalLabel {
      font-size: 10px;
      line-height: 8px;
    }
  }
}
.range_button {
  margin: 10px;
  width: 100%;
  padding: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.active_range {
  color: white;
  background-color: #fe1c1d;
}
.md-field {
  margin: 0;
  min-height: 0;
}
</style>

<style>
.theme-red .vdatetime-popup__header,
.theme-red .vdatetime-calendar__month__day--selected > span > span,
.theme-red .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #fe1c1d;
}
.theme-red .vdatetime-year-picker__item--selected,
.theme-red .vdatetime-time-picker__item--selected,
.theme-red .vdatetime-month-picker__item--selected,
.theme-red .vdatetime-popup__actions__button {
  color: #fe1c1d;
}
</style>
