import Vue from 'vue';
import VueRouter from 'vue-router';
import login from '../views/login.vue';
import customersDashboards from '@/views/customersDashboards';
import monitorsDashboards from '@/views/monitorsDashboards';
import settings from '@/views/settings';
import assetList from '@/views/assetList';
import assetItem from '@/views/assetItem';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings,
  },
  {
    path: '/customers',
    name: 'customers',
    component: customersDashboards,
  },
  {
    path: '/monitors',
    name: 'monitors',
    component: monitorsDashboards,
  },
  {
    path: '/assetList',
    name: 'assetList',
    component: assetList,
  },
  {
    path: '/assetItem',
    name: 'assetItem',
    component: assetItem,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
