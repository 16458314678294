<template>
  <div class="login_page md-layout">
    <div class="loginPage_pictureContainer md-layout-item md-size-50 md-small-size-100" />
    <form
      style="position: relative"
      novalidate
      class="
        main_form
        md-layout md-gutter md-alignment-center-center md-layout-item md-size-50 md-small-size-100
      "
      @submit.prevent="validateUser"
    >
      <img class="login_logo" src="@/assets/login_logo.svg" />
      <div class="container md-layout-item md-size-50 md-small-size-50">
        <md-card-content class="loginForm">
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('userName')">
                <label for="Username">Username</label>
                <md-input
                  id="Username"
                  v-model="form.userName"
                  class="login_input"
                  name="first-name"
                  autocomplete="off"
                  :disabled="sending"
                />
                <span v-if="!$v.form.userName.required" class="md-error"
                  >The Username is required</span
                >
                <span v-else-if="!$v.form.userName.minlength" class="md-error"
                  >Invalid Username</span
                >
              </md-field>
            </div>
          </div>

          <md-field :md-toggle-password="false" :class="getValidationClass('password')">
            <label for="password">Password</label>
            <md-input
              id="password"
              v-model="form.password"
              class="login_input"
              type="password"
              name="password"
              autocomplete="password"
              :disabled="sending"
            />
            <span v-if="!$v.form.password.required" class="md-error">The password is required</span>
            <span v-else-if="!$v.form.password.password && !isloginFailed" class="md-error"
              >Invalid password</span
            >
          </md-field>
        </md-card-content>
        <span v-if="isloginFailed" class="md-error" style="text-align: left"
          >You have entered an invalid username or password</span
        >
        <md-progress-bar v-if="sending" md-mode="indeterminate" />

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending"> Login </md-button>
        </md-card-actions>
      </div>

      <md-snackbar :md-active="userSaved">
        The user {{ lastUser }} was saved with success!
      </md-snackbar>
    </form>
  </div>
</template>

<script>
import { Bus as VuedalsBus } from 'vuedals';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { validatePass } from '../utils/validators';
import router from '../router';
import UserService from '../services/user';

export default {
  name: 'Login',
  mixins: [validationMixin],
  data: () => ({
    isloginFailed: null,
    form: {
      userName: null,
      password: null,
    },
    userService: new UserService(),
    userSaved: false,
    sending: false,
    lastUser: null,
  }),
  validations: {
    form: {
      userName: {
        required,
        maxLength: maxLength(20),
        minLength: minLength(5),
      },
      password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(8),
        validatePass,
      },
    },
  },
  created() {
    VuedalsBus.$emit('close', []);
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
      return null;
    },
    clearForm() {
      this.$v.$reset();
      this.form.userName = null;
      this.form.password = null;
    },
    saveUser() {
      this.sending = true;
      this.userService.loginUser(this.form).subscribe(
        (resp) => {
          const { user } = resp.data.message;
          this.$store.commit('saveToken', resp.data.message.accessToken);
          this.$store.commit('saveUser', user);
          this.sending = false;
          this.isloginFailed = false;
          if (user.role === 'customer') {
            router.push('/assetList');
          } else {
            router.push('/customers');
          }
        },
        () => {
          this.isloginFailed = true;
          this.sending = false;
        }
      );
      // Instead of this timeout, here you can call your API
      // window.setTimeout(() => {
      //   this.lastUser = `${this.form.userName} ${this.form.lastName}`;
      //   this.userSaved = true;
      //   this.sending = false;
      //   this.clearForm();
      // }, 1500);
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveUser();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login_input {
  padding-left: 5px !important;
}
.login_page {
  background: #2e2e2e;
  height: 100%;
}
.main_form {
  background: #2e2e2e;
  position: relative;
  .container {
    md-field {
      color: white !important;
    }
    background: #2e2e2e;
    border: 0;
    color: white !important;
    box-shadow: 0 !important;
  }
}
.md-theme-default,
input {
  color: white !important;
  -webkit-text-fill-color: unset !important;
  box-shadow: 0 !important;
}
input:focus {
  border: 0 !important;
}
.md-card {
  box-shadow: 0 !important;
}
.md-field:before {
  background-color: unset !important;
}
.md-field:after {
  background-color: #afafaf !important;
  background-image: none !important;
}
label {
  top: 0px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  color: #afafaf !important;
  .md-focused {
    display: none;
  }
}
button {
  background: #fe1c1d;
  width: 100%;
}
.md-button {
  .md-ripple {
    display: none;
  }
}
.login_logo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 50px;
}

.loginPage_pictureContainer {
  background-image: url('../assets/admin_login_pic.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.md-progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.loginForm {
  input {
    margin-top: 14px;
  }
}
</style>
;
