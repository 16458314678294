import ApiService from './api';

class DashboardsService {
  constructor() {
    this.apiService = new ApiService();
  }

  getUsers(options) {
    let url = `/api/v1/users`;
    if (options) {
      const optionsParams = new URLSearchParams();
      if (options.limit) {
        optionsParams.set('limit', +options.limit);
      }
      if (options.offset) {
        optionsParams.set('offset', +options.offset);
      }
      if (options.search) {
        optionsParams.set('search', options.search);
      }
      optionsParams.set('role', 'customer');
      url += `/?${optionsParams.toString()}`;
    }
    return this.apiService.get(url);
  }

  getDevices() {
    return this.apiService.get('/api/v1/devices');
  }

  getDevicesBySites(options) {
    let url = '/api/v1/devices';
    if (options) {
      const optionsParams = new URLSearchParams();
      if (options.siteId) {
        optionsParams.set('siteId', options.siteId);
      }
      if (options.limit) {
        optionsParams.set('limit', options.limit);
      }
      if (Number.isInteger(options.offset)) {
        optionsParams.set('offset', options.offset);
      }
      if (options.search) {
        optionsParams.set('search', options.search);
      }
      url += `/?${optionsParams.toString()}`;
    }
    return this.apiService.get(url);
  }

  getSites() {
    return this.apiService.get('/api/v1/sites');
  }

  loadImageDevice(deviceID, body) {
    const formData = new FormData();
    formData.append('', body);
    return this.apiService.post(`/api/v1/devices/${deviceID}/image`, formData, {
      'Content-Type': 'multipart/form-data',
    });
  }

  updateImageDevice(deviceID, body) {
    const formData = new FormData();
    formData.append('', body);
    return this.apiService.put(`/api/v1/devices/${deviceID}/image`, formData, {
      'Content-Type': 'multipart/form-data',
    });
  }

  createCustomer(body) {
    return this.apiService.post('/api/v1/users', body);
  }

  editCustomer(body) {
    return this.apiService.put(`/api/v1/users/${body.id}`, body);
  }

  deleteCustomer(id) {
    return this.apiService.delete(`/api/v1/users/${id}`);
  }

  deleteDevice(id) {
    return this.apiService.delete(`/api/v1/devices/${id}`);
  }

  createDevice(body) {
    return this.apiService.post('/api/v1/devices', body);
  }

  updateDevice(body) {
    return this.apiService.put(`/api/v1/devices/${body.id}`, body);
  }
}

export default DashboardsService;
