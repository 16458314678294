<template>
  <div v-if="options" class="paginator_main">
    <div><span>Rows per page:</span></div>
    <div>
      <select :value="options.limit" @change="changeLimit($event)">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
      </select>
      <span> page {{ currentPage }} of {{ amountOfpages }} </span>
    </div>
    <div class="navigation_controls">
      <img src="@/assets/navigation_arrow.svg" @click="changePage(false)" />
      <img
        style="transform: rotate(180deg)"
        src="@/assets/navigation_arrow.svg"
        @click="changePage(true)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNavigator',
  props: {
    list: null,
    options: null,
  },
  data: () => ({
    currentPage: 1,
    amountOfpages: null,
  }),
  mounted() {
    const { limit } = this.options;
    const { count } = this.options;

    this.amountOfpages = Math.ceil(+count / +limit);
  },
  updated() {
    const { limit } = this.options;
    const { count } = this.options;
    const { offset } = this.options;
    this.currentPage = offset / limit + 1;
    this.amountOfpages = Math.ceil(+count / +limit);
  },
  methods: {
    changePage(isNextPage = null) {
      const { offset } = this.options;
      const { limit } = this.options;
      const { count } = this.options;

      const currentOffset = isNextPage ? +offset + +limit : +offset - +limit;

      const currentPage = currentOffset / limit;
      if (count >= currentOffset && currentOffset >= 0 && +this.amountOfpages > +currentPage) {
        this.updateOptions({
          ...this.options,
          offset: currentOffset,
        });
      }
    },
    changeLimit(e) {
      this.updateOptions({ ...this.options, limit: e.target.value, offset: 0 });
    },
    updateOptions(options) {
      this.$emit('optionsChange', { ...options });
    },
  },
};
</script>
<style scoped lang="scss">
.paginator_main {
  padding: 0 63px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  span,
  select {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    color: #9ea0a5;
  }
  select {
    background: unset !important;
    border: unset !important;
  }
  .navigation_controls {
    img {
      cursor: pointer;
    }
    width: 85px;
    display: flex;
    justify-content: space-between;
  }
  div {
    margin-left: 79px;
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
