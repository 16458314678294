<template>
  <div style="width: 100%; flex-direction: column; padding: 6% 3% 4%">
    <div class="customers_header">
      <div class="heading">
        <h1>Customers</h1>
      </div>
      <div class="search_container md-layout">
        <searchComponent
          :placeholder="'Search customers by name'"
          :options="usersOptions"
          @optionsChange="optionsChange"
        />
        <md-button class="md-raised md-primary" @click="openCustomer('create')">
          <img class="btnIcon" src="@/assets/plus_icon.svg" />
          Add customer
        </md-button>
      </div>
    </div>
    <div class="customers_table md-layout-item">
      <div class="table_header">
        <div>Customer Name</div>
        <div>Username</div>
        <div style="justify-content: flex-end">Actions</div>
      </div>
      <div class="tabel_body">
        <div v-for="item in users" :key="item.id" class="tabel_body_row">
          <div>
            <span>{{ item.name }}</span>
          </div>
          <div>
            <span>{{ item.username }}</span>
          </div>
          <div style="justify-content: space-between">
            <img src="@/assets/pencil_icon.svg" @click="openCustomer('edit', item)" />
            <img src="@/assets/bucket_icon.svg" @click="openDeleteCustomer(item)" />
          </div>
        </div>
        <div v-if="!users || !(users && users.length)" class="noDataCustomersContainer">
          <img src="@/assets/empty_customers_icon.svg" />
          <span style="font-size: 16px"><strong>No added customers</strong></span>
          <span style="font-size: 12px; opacity: 0.5">Start adding customers</span>
          <md-button class="md-raised md-primary" @click="openCustomer('create')">
            Add customer
          </md-button>
        </div>
      </div>
      <div class="footer">
        <pageNavigator :list="users" :options="usersOptions" @optionsChange="optionsChange" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.noDataCustomersContainer {
  img {
    margin-bottom: 27px;
  }
  button {
    background-color: #fe1c1d !important;
    width: 155px;
    margin-top: 19px;
  }
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.footer {
  border-top: 1px solid #e4e7eb;
  height: 60px;
  background-color: white;
  width: 100%;
}
.customers_table {
  display: grid;
  height: calc(100% - 156px);
  grid-template-rows: 56px 1fr 65px;
  .table_header {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.05px;
    color: #9ea0a5;
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      letter-spacing: 0.2px;

      color: #fafafa;
    }
  }
  .tabel_body_row:hover {
    background: #fafafa;
  }
  .tabel_body {
    position: relative;
    overflow-y: auto;
  }
  .table_header,
  .tabel_body_row {
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      letter-spacing: -0.05px;

      color: #2e2e2e;
    }
    img {
      cursor: pointer;
    }
    display: grid;
    height: 56px;
    grid-template-columns: 1fr 3fr 145px;
    border-bottom: 1px solid #e4e7eb;
    div {
      display: flex;
      align-items: center;
      padding: 0 60px 0 28px;
    }
  }
  background-color: white;
  width: 100%;
}
.customers_header {
  width: 100%;
}
.heading {
  text-align: left;
}
.search_container {
  text-align: left;
}
.add_customer {
  text-align: right;
}
.search_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 41px 0 30px 0;
  .plus_symbol {
    font-size: 27px;
    top: 0px;
    left: -20px;
    position: absolute;
  }
  button {
    background-color: #fe1c1d !important;
    height: 36px;
    width: 180px;
    padding: 10px;
    text-align: right !important;
  }
  .md-button {
  }
  .btnIcon {
    position: relative;
    top: 0;
    left: 0;
    margin-right: 10px;
    margin-bottom: 3px;
  }
  img {
    position: absolute;
    top: 15px;
    left: 10px;
  }
  input {
    padding-left: 44px;
    background: #ffffff;
    box-shadow: 0px 0px 0px #e4e7eb;
    border-radius: 4px;
    border: 0;
    height: 36px;
    max-width: 620px;
    width: 620px;
  }
}
</style>
<script>
import {
  // default as Vuedals,
  // Component as Vuedal,
  Bus as VuedalsBus,
} from 'vuedals';
import DashboardsService from '../services/dashboards';
import customerViewModal from './customersViewCustomerModal.vue';
import deleteCustomer from './customersDeleteModal.vue';

export default {
  name: 'Customers',
  data: () => ({
    dashboardsService: new DashboardsService(),
    newsletterPopup: { type: String, default: 'Vue!' },
    users: [],
    usersOptions: { limit: 5, offset: 0 },
  }),
  methods: {
    optionsChange(e) {
      this.usersOptions = { ...this.usersOptions, ...e };
      this.$router.replace({
        query: {
          limit: this.usersOptions.limit,
          offset: this.usersOptions.offset,
        },
      });
      this.initData();
    },
    initData() {
      this.dashboardsService.getUsers({ ...this.usersOptions }).subscribe((resp) => {
        const users = resp.data.message.rows;
        this.usersOptions = {
          ...this.usersOptions,
          count: resp.data.message.count,
        };
        const sortedUsers = [];

        users.forEach((element) => {
          if (element.role === 'customer') {
            sortedUsers.push(element);
          }
        });

        this.users = sortedUsers;
      });
    },
    openCustomer(mode = null, customer = null) {
      VuedalsBus.$emit('new', {
        props: {
          customer,
          mode,
          initData: this.initData,
        },
        component: customerViewModal,
        closeOnBackdrop: false,
      });
    },
    openDeleteCustomer(customer = null) {
      VuedalsBus.$emit('new', {
        props: {
          id: customer.id,
          customer,
          initData: this.initData,
        },
        component: deleteCustomer,
        closeOnBackdrop: false,
      });
    },
  },
  beforeMount() {
    if (Object.keys(this.$route.query).length) {
      this.usersOptions = { ...this.$route.query };
    }
    this.initData();
  },
};
</script>
