<template>
  <div class="settings_main md-layout-item md-size-45">
    <div class="settings_header">
      <h1>Settings</h1>
    </div>
    <md-card class="md-layout-item md-size-100 settings_form_wrapper">
      <form
        autocomplete="off"
        class="md-layout md-layout-item settings_form"
        @submit.prevent="validateUser"
      >
        <div class="settings_body md-layout">
          <div class="md-layout-item md-size-100">
            <label for="username">Username</label>
            <input
              id="username"
              v-model="form.userName"
              :class="getValidationClass('userName')"
              class="md-layout-item md-size-100"
              readonly
            />
            <!-- <span
              class="md-error"
              v-if="$v.form.userName.required && $v.form.userName.$dirty"
              >Required</span
            > -->
            <span v-if="!$v.form.userName.maxLength && $v.form.userName.$dirty" class="md-error"
              >Should have 64 characters</span
            >
          </div>
          <div class="md-layout-item md-size-100" style="grid-column-start: 1">
            <label for="new_password">New Password</label>
            <input
              id="new_password"
              v-model="form.newPassword"
              type="password"
              :class="getValidationClass('newPassword')"
              class="md-layout-item md-size-100"
            />
            <span
              v-if="
                form.newPassword === form.confirmPassword &&
                form.newPassword === form.currentPassword &&
                $v.$anyDirty
              "
              class="md-error"
              >Current and new password are the same</span
            >
            <span v-if="!$v.form.newPassword.required && $v.$anyDirty" class="md-error"
              >Required</span
            >
            <span
              v-if="
                $v.form.newPassword.$invalid &&
                $v.form.newPassword.$dirty &&
                $v.form.newPassword.required
              "
              class="md-error"
              >Should have 8-16 characters, alphabet, numeric and !#$%<span>{{ '&' }}</span
              >*+-.?@_ symbols</span
            >
          </div>
          <div class="md-layout-item md-size-100">
            <label for="confirm_password">Confirm Password</label>
            <input
              id="confirm_password"
              v-model="form.confirmPassword"
              type="password"
              :class="getValidationClass('confirmPassword')"
              class="md-layout-item md-size-100"
            />
            <span v-if="form.newPassword !== form.confirmPassword && $v.$anyDirty" class="md-error"
              >The confirmed password does not match with the input</span
            >
            <span
              v-if="
                $v.form.confirmPassword.$invalid &&
                $v.form.confirmPassword.$dirty &&
                $v.form.confirmPassword.required
              "
              class="md-error"
              >Should have 8-16 characters, alphabet, numeric and !#$%<span>{{ '&' }}</span
              >*+-.?@_ symbols</span
            >
          </div>

          <div class="md-layout-item md-size-100" style="grid-row-start: 2">
            <label for="current_password">Current Password</label>
            <input
              id="current_password"
              v-model="form.currentPassword"
              type="password"
              :class="getValidationClass('currentPassword')"
              class="md-layout-item md-size-100"
              @input="isCurrentPassIncorrect = false"
            />
            <!-- <span
              class="md-error"
              v-if="!$v.form.currentPassword.$invalid && $v.$anyDirty"
              >The current password is required</span
            > -->
            <span v-if="isCurrentPassIncorrect" class="md-error"
              >Current password is incorrect</span
            >
            <span v-if="!$v.form.currentPassword.required && $v.$anyDirty" class="md-error"
              >Required</span
            >
            <span
              v-if="
                $v.form.currentPassword.$invalid &&
                $v.form.currentPassword.$dirty &&
                $v.form.currentPassword.required
              "
              class="md-error"
              >Should have 8-16 characters, alphabet, numeric and !#$%<span>{{ '&' }}</span
              >*+-.?@_ symbols</span
            >
          </div>
          <div style="grid-column-end: 3; grid-column-start: 1">
            <md-button
              style="max-height: 40px; background-color: #fe1c1d"
              class="md-raised md-primary md-layout-item md-size-100"
              type="submit"
              @click="submitForm()"
            >
              APPLY
            </md-button>
          </div>
        </div>
      </form>
    </md-card>
  </div>
</template>
<style scoped lang="scss">
button {
  min-height: 40px;
}
.md-invalid {
  border: 1px solid #fe1c1d !important;
}
.settings_header {
  text-align: left;
}
input {
  min-height: 34px;
}
.md-error {
  text-align: left;
  color: #fe1c1d;
}
.settings_main {
  padding: 117px 50px;
  input {
    color: #afafaf;
    padding: 5px 10px;
    max-height: 34px;
    background: #ffffff;
    border: 1px solid #afafaf;
    box-sizing: border-box;
  }
  .settings_body {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    div {
      display: flex;
      flex-direction: column;
      label {
        text-align: left;
        color: #2e2e2e;
        margin-bottom: 9px !important;
      }
    }
    display: grid;
    grid-gap: 32px 30px;
    padding: 50px;
    grid-template-columns: 1fr 1fr;
  }
}
.settings_form_wrapper {
  .settings_form {
    height: 100%;
    max-height: 550px;
    overflow: auto;
  }
}
@media screen and (max-width: 1024px) {
  .settings_main {
    max-width: 100% !important;
    flex: 0 1 100% !important;
  }
  .settings_body {
    div {
      label {
        font-size: 13px;
      }
    }
  }
}
</style>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { validatePass } from '../utils/validators';
import ApiService from '../services/api';
import UserService from '../services/user';

export default {
  name: 'Setting',
  mixins: [validationMixin],
  data: () => ({
    api: new ApiService(),
    userService: new UserService(),
    currentUser: null,
    form: {
      userName: null,
      newPassword: null,
      currentPassword: null,
      confirmPassword: null,
    },
    isFormSubmited: false,
    isCurrentPassIncorrect: false,
  }),
  validations: {
    form: {
      userName: {
        maxLength: maxLength(64),
      },
      newPassword: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(8),
        validatePass,
      },
      currentPassword: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(8),
        validatePass,
      },
      confirmPassword: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(8),
        validatePass,
      },
    },
  },
  mounted() {
    this.api.updateToken().then(() => {
      this.form.userName = this.$store.state?.user.name;
    });

    this.$store.subscribe((a) => {
      if (a.type === 'saveUser') {
        this.currentUser = a.payload;
        this.form.userName = a.payload.name;
      }
    });
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
      return null;
    },
    clearForm() {
      // this.$v.$reset();
      // this.form.userName = null;
      // this.form.currentPassword = null;
      // this.form.newPassword = null;
      // this.form.confirmPassword = null;
    },
    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid && this.form.newPassword === this.form.confirmPassword) {
        this.userService
          .changePassword(this.form.currentPassword, this.form.newPassword, this.currentUser.id)
          .subscribe(
            () => {
              this.clearForm();
            },
            (error) => {
              if (error.data.status === 403) {
                this.isCurrentPassIncorrect = true;
              }
            }
          );
      }
    },
    submitForm() {},
  },
};
</script>
